import React from "react"
// import { AnchorLink } from "gatsby-plugin-anchor-links"
import content from "../../content/hero.yaml"

export default () => (
  <section id="hero">
    <div className="row">
      <div className="twelve columns">
        <div className="animated fadeInUpBig padding-top-20">
          {/* <img src={ content.image } alt="Beneathatree logo" /> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            // width="297.00003mm"
            width="100%"
            // height="57.029221mm"
            viewBox="0 0 297.00002 57.02922"
            version="1.1"
            id="svg4535">
            <defs
              id="defs4529" />
            <g
              id="layer1"
              transform="translate(-2.443488,-59.256891)">
              <g
                id="g5947"
                transform="matrix(0.06118616,0,0,0.06118616,-23.269349,112.49697)">
                <g
                  aria-label="beneathatree"
                  style={{fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'12.80000019px',lineheight:'0%',fontfamily:'Ubuntu',InkscapeFontSpecification:'Ubuntu',letterSpacing:'0px',wordSpacing:'0px',fill:'#40a184',fillOpacity:'1',stroke:'none',strokeWidth:'1.06666672px',strokeLinecap:'butt',strokeLinejoin:'miter',strokeOpacity:'1'}}
                  id="text6006-6-1"
                  transform="matrix(0.99970614,0,0,0.99970614,1.5838565,-0.2556935)">
                  <path
                    d="m 878.58046,-331.16177 q 13.13789,-8.50098 39.41366,-17.77478 27.04859,-9.27381 61.82536,-9.27381 43.27772,0 76.50882,15.45634 34.0039,15.45634 57.1885,43.27775 23.1845,27.82141 34.7767,66.46225 12.3651,38.64086 12.3651,85.00987 0,48.687474 -14.6835,88.101134 -13.9107,38.640847 -40.1865,65.6894399 -26.2758,27.0485941 -63.371,41.7321151 -37.0951,14.683522 -83.46417,14.683522 -50.23311,0 -88.87395,-6.955352 -38.64086,-6.955353 -63.371,-13.910706 V -534.41262 l 71.87198,-12.36507 z m 0,321.4918548 q 10.81943,3.091268 30.13986,6.1825358 20.09324,2.3184509 49.46029,2.3184509 57.96129,0 92.73809,-37.8680305 34.7767,-38.640847 34.7767,-108.967191 0,-30.91268 -6.1826,-57.96127 -6.1825,-27.0486 -20.0932,-46.36901 -13.9107,-20.09325 -36.3224,-30.91268 -21.6389,-11.59226 -52.55152,-11.59226 -29.36705,0 -54.09719,10.04662 -24.73014,10.04663 -37.86803,20.86606 z"
                    style={{fontSize:'781.6506958px',lineHeight:'1.25',fill:'#40a184',fillOpacity:'1',strokeWidth:'1.05461192px'}}
                    id="path5144" />
                  <path
                    d="m 1212.6344,-147.23133 q 0,-53.32437 15.4564,-92.73803 15.4563,-40.18649 40.9592,-66.46226 25.503,-26.27578 58.7341,-39.41367 33.2312,-13.13789 68.008,-13.13789 81.1457,0 124.4234,51.00593 43.2778,50.23309 43.2778,153.79056 0,4.63691 0,12.36508 0,6.95535 -0.7728,13.13789 h -275.1228 q 4.6369,62.598169 36.3223,95.05648 31.6855,32.4583115 98.9206,32.4583115 37.868,0 63.371,-6.1825356 26.2758,-6.9553529 39.4137,-13.1378879 l 10.0466,60.279722 q -13.1379,6.955352 -46.369,14.683522 -32.4583,7.728169 -74.1905,7.728169 -52.5515,0 -91.1924,-15.456339 -37.8679,-16.229156 -62.5982,-44.0505659 -24.7301,-27.8214101 -37.0951,-65.6894401 -11.5923,-38.640846 -11.5923,-84.237046 z M 1488.53,-186.645 q 0.7729,-48.68747 -24.7301,-79.60015 -24.7301,-31.68549 -68.7807,-31.68549 -24.7301,0 -44.0506,10.04662 -18.5476,9.2738 -31.6855,24.73015 -13.1378,15.45633 -20.866,35.54958 -6.9554,20.09324 -9.2738,40.95929 z"
                    style={{fontSize:'781.6506958px',lineHeight:'1.25',fill:'#40a184',fillOpacity:'1',strokeWidth:'1.05461192px'}}
                    id="path5146" />
                  <path
                    d="m 1625.3201,-337.3443 q 24.7302,-6.18254 65.6894,-13.13789 40.9593,-6.95535 94.2837,-6.95535 47.9147,0 79.6001,13.9107 31.6855,13.1379 50.2331,37.86804 19.3205,23.95732 27.0487,57.96126 7.7281,34.00395 7.7281,74.96325 V 52.928258 h -71.8719 V -157.27795 q 0,-37.09521 -5.4097,-63.371 -4.6369,-26.27577 -16.2292,-42.50492 -11.5923,-16.22916 -30.9127,-23.18451 -19.3204,-7.72817 -47.9147,-7.72817 -11.5922,0 -23.9573,0.77282 -12.3651,0.77281 -23.9573,2.31844 -10.8194,0.77282 -20.0932,2.31845 -8.501,1.54564 -12.3651,2.31846 V 52.928258 h -71.872 z"
                    style={{fontSize:'781.6506958px',lineHeight:'1.25',fill:'#40a184',fillOpacity:'1',strokeWidth:'1.05461192px'}}
                    id="path5148" />
                  <path
                    d="m 2005.2575,-147.23133 q 0,-53.32437 15.4565,-92.73803 15.4563,-40.18649 40.9592,-66.46226 25.503,-26.27578 58.7341,-39.41367 33.2312,-13.13789 68.0079,-13.13789 81.1458,0 124.4235,51.00593 43.2778,50.23309 43.2778,153.79056 0,4.63691 0,12.36508 0,6.95535 -0.7728,13.13789 h -275.1228 q 4.6369,62.598169 36.3223,95.05648 31.6855,32.4583115 98.9206,32.4583115 37.868,0 63.371,-6.1825356 26.2758,-6.9553529 39.4137,-13.1378879 l 10.0465,60.279722 q -13.1378,6.955352 -46.3689,14.683522 -32.4583,7.728169 -74.1905,7.728169 -52.5515,0 -91.1924,-15.456339 -37.868,-16.229156 -62.5982,-44.0505659 -24.7301,-27.8214101 -37.0952,-65.6894401 -11.5923,-38.640846 -11.5923,-84.237046 z m 275.8957,-39.41367 q 0.7729,-48.68747 -24.7301,-79.60015 -24.7301,-31.68549 -68.7807,-31.68549 -24.7301,0 -44.0506,10.04662 -18.5476,9.2738 -31.6855,24.73015 -13.1378,15.45633 -20.866,35.54958 -6.9554,20.09324 -9.2738,40.95929 z"
                    style={{fontSize:'781.6506958px',lineHeight:'1.25',fill:'#40a184',fillOpacity:'1',strokeWidth:'1.05461192px'}}
                    id="path5150" />
                  <path
                    d="m 2567.8722,1.1495222 q 25.503,0 44.8234,-0.77281694 20.0932,-1.54563376 33.2311,-4.63690156 V -124.04683 q -7.7281,-3.86408 -25.503,-6.18253 -17.0019,-3.09127 -41.732,-3.09127 -16.2292,0 -34.7768,2.31846 -17.7748,2.31845 -33.2312,10.04661 -14.6834,6.95536 -24.7301,20.09324 -10.0467,12.365076 -10.0467,33.231134 0,38.640848 24.7303,54.097187 24.7301,14.6835212 67.235,14.6835212 z m -6.1825,-360.1327022 q 43.2777,0 72.6447,11.59226 30.1399,10.81944 47.9147,31.68549 18.5476,20.09325 26.2758,48.68747 7.7282,27.82141 7.7282,61.82536 V 45.972905 q -9.2738,1.545634 -26.2758,4.636902 -16.2292,2.318451 -37.0952,4.636902 -20.8661,2.318451 -45.5962,3.864084 -23.9573,2.318451 -47.9147,2.318451 -34.0039,0 -62.5982,-6.955352 -28.5942,-6.955353 -49.4602,-21.638874 -20.8661,-15.456339 -32.4584,-40.1864821 -11.5922,-24.7301419 -11.5922,-59.5069049 0,-33.231131 13.1378,-57.188461 13.9108,-23.95732 37.0953,-38.64084 23.1845,-14.68353 54.0972,-21.63887 30.9127,-6.95536 64.9166,-6.95536 10.8195,0 22.4117,1.54563 11.5922,0.77282 21.6389,3.09127 10.8194,1.54564 18.5476,3.09127 7.7282,1.54564 10.8194,2.31845 v -20.09324 q 0,-17.77478 -3.8641,-34.77676 -3.864,-17.7748 -13.9107,-30.91268 -10.0466,-13.9107 -27.8214,-21.63888 -17.0019,-8.50098 -44.8233,-8.50098 -35.5497,0 -62.5982,5.40972 -26.2758,4.6369 -39.4137,10.04662 l -8.501,-59.5069 q 13.9107,-6.18254 46.3691,-11.59226 32.4583,-6.18254 70.3263,-6.18254 z"
                    style={{fontSize:'781.6506958px',lineHeight:'1.25',fill:'#40a184',fillOpacity:'1',strokeWidth:'1.05461192px'}}
                    id="path5152" />
                  <path
                    d="m 2860.6868,-348.93655 h 152.2449 v 60.27971 h -152.2449 v 185.47607 q 0,30.139865 4.6369,50.233106 4.6368,19.320424 13.9106,30.912678 9.2738,10.819437 23.1846,15.4563388 13.9107,4.6369017 32.4583,4.6369017 32.4583,0 51.7788,-6.9553525 20.0932,-7.728169 27.8213,-10.819437 l 13.9107,59.506905 q -10.8194,5.409719 -37.868,13.137888 -27.0485,8.500986 -61.8253,8.500986 -40.9593,0 -68.0079,-10.04662 -26.2758,-10.819437 -42.505,-31.685495 -16.2291,-20.8660575 -23.1845,-51.005918 -6.1825,-30.912678 -6.1825,-71.099161 v -358.58706 l 71.872,-12.36507 z"
                    style={{fontSize:'781.6506958px',lineHeight:'1.25',fill:'#40a184',fillOpacity:'1',strokeWidth:'1.05461192px'}}
                    id="path5154" />
                  <path
                    d="M 3076.8861,52.928258 V -534.41262 l 71.8719,-12.36507 v 205.56931 q 20.0933,-7.72817 42.505,-11.59226 23.1845,-4.6369 45.5962,-4.6369 47.9146,0 79.6001,13.9107 31.6855,13.1379 50.2331,37.86804 19.3205,23.95732 27.0486,57.96126 7.7282,34.00395 7.7282,74.96325 V 52.928258 h -71.872 V -157.27795 q 0,-37.09521 -5.4097,-63.371 -4.6369,-26.27577 -16.2291,-42.50492 -11.5923,-16.22916 -30.9128,-23.18451 -19.3203,-7.72817 -47.9146,-7.72817 -11.5923,0 -23.9573,1.54563 -12.3651,1.54563 -23.9574,3.86408 -10.8194,1.54564 -20.0932,3.86409 -8.5009,2.31845 -12.3651,3.86409 V 52.928258 Z"
                    style={{fontSize:'781.6506958px',lineHeight:'1.25',fill:'#40a184',fillOpacity:'1',strokeWidth:'1.05461192px'}}
                    id="path5156" />
                  <path
                    d="m 3626.1454,1.1495222 q 25.5029,0 44.8234,-0.77281694 20.0932,-1.54563376 33.2311,-4.63690156 V -124.04683 q -7.7282,-3.86408 -25.503,-6.18253 -17.0019,-3.09127 -41.7321,-3.09127 -16.2292,0 -34.7767,2.31846 -17.7749,2.31845 -33.2312,10.04661 -14.6834,6.95536 -24.7301,20.09324 -10.0467,12.365076 -10.0467,33.231134 0,38.640848 24.7302,54.097187 24.7302,14.6835212 67.2351,14.6835212 z m -6.1825,-360.1327022 q 43.2777,0 72.6447,11.59226 30.1399,10.81944 47.9147,31.68549 18.5476,20.09325 26.2758,48.68747 7.7281,27.82141 7.7281,61.82536 V 45.972905 q -9.2738,1.545634 -26.2757,4.636902 -16.2292,2.318451 -37.0952,4.636902 -20.8661,2.318451 -45.5962,3.864084 -23.9574,2.318451 -47.9147,2.318451 -34.0039,0 -62.5982,-6.955352 -28.5942,-6.955353 -49.4602,-21.638874 -20.8661,-15.456339 -32.4584,-40.1864821 -11.5922,-24.7301419 -11.5922,-59.5069049 0,-33.231131 13.1378,-57.188461 13.9107,-23.95732 37.0953,-38.64084 23.1845,-14.68353 54.0971,-21.63887 30.9127,-6.95536 64.9167,-6.95536 10.8194,0 22.4116,1.54563 11.5923,0.77282 21.639,3.09127 10.8194,1.54564 18.5476,3.09127 7.7281,1.54564 10.8194,2.31845 v -20.09324 q 0,-17.77478 -3.8641,-34.77676 -3.8641,-17.7748 -13.9107,-30.91268 -10.0466,-13.9107 -27.8214,-21.63888 -17.002,-8.50098 -44.8233,-8.50098 -35.5497,0 -62.5982,5.40972 -26.2759,4.6369 -39.4137,10.04662 l -8.501,-59.5069 q 13.9107,-6.18254 46.3691,-11.59226 32.4582,-6.18254 70.3263,-6.18254 z"
                    style={{opacity:'0.98999999',fill:'#999999',fillOpacity:'1',strokeWidth:'1.05461192px'}}
                    id="path5158" />
                  <path
                    d="m 3905.0468,-348.93655 h 152.245 v 60.27971 h -152.245 v 185.47607 q 0,30.139865 4.6369,50.233106 4.6369,19.320424 13.9107,30.912678 9.2738,10.819437 23.1846,15.4563388 13.9107,4.6369017 32.4583,4.6369017 32.4583,0 51.7787,-6.9553525 20.0933,-7.728169 27.8214,-10.819437 l 13.9107,59.506905 q -10.8194,5.409719 -37.868,13.137888 -27.0486,8.500986 -61.8253,8.500986 -40.9593,0 -68.008,-10.04662 -26.2757,-10.819437 -42.5049,-31.685495 -16.2291,-20.8660575 -23.1845,-51.005918 -6.1825,-30.912678 -6.1825,-71.099161 v -358.58706 l 71.8719,-12.36507 z"
                    style={{fontSize:'781.6506958px',lineHeight:'1.25',fill:'#40a184',fillOpacity:'1',strokeWidth:'1.05461192px'}}
                    id="path5160" />
                  <path
                    d="m 4269.627,-357.43754 q 9.2738,0 20.8661,1.54563 12.365,0.77282 23.9573,3.09127 11.5922,1.54564 20.866,3.86409 10.0467,1.54563 14.6836,3.09126 l -12.3651,62.59818 q -8.501,-3.09127 -28.5942,-6.95536 -19.3205,-4.6369 -50.2332,-4.6369 -20.0932,0 -40.1864,4.6369 -19.3205,3.86409 -25.503,5.40972 V 52.928258 h -71.8719 V -331.93459 q 25.5029,-9.27379 63.3709,-17.00196 37.868,-8.50099 85.0099,-8.50099 z"
                    style={{fontSize:'781.6506958px',lineHeight:'1.25',fill:'#40a184',fillOpacity:'1',strokeWidth:'1.05461192px'}}
                    id="path5162" />
                  <path
                    d="m 4370.1937,-147.23133 q 0,-53.32437 15.4564,-92.73803 15.4563,-40.18649 40.9593,-66.46226 25.5029,-26.27578 58.734,-39.41367 33.2312,-13.13789 68.0079,-13.13789 81.1458,0 124.4236,51.00593 43.2777,50.23309 43.2777,153.79056 0,4.63691 0,12.36508 0,6.95535 -0.7729,13.13789 H 4445.157 q 4.6369,62.598169 36.3224,95.05648 31.6855,32.4583115 98.9206,32.4583115 37.8679,0 63.3709,-6.1825356 26.2757,-6.9553529 39.4137,-13.1378879 l 10.0466,60.279722 q -13.1379,6.955352 -46.369,14.683522 -32.4583,7.728169 -74.1904,7.728169 -52.5516,0 -91.1924,-15.456339 -37.8681,-16.229156 -62.5982,-44.0505659 -24.7302,-27.8214101 -37.0953,-65.6894401 -11.5922,-38.640846 -11.5922,-84.237046 z m 275.8957,-39.41367 q 0.7728,-48.68747 -24.7302,-79.60015 -24.7302,-31.68549 -68.7807,-31.68549 -24.7301,0 -44.0506,10.04662 -18.5476,9.2738 -31.6854,24.73015 -13.1379,15.45633 -20.8661,35.54958 -6.9554,20.09324 -9.2738,40.95929 z"
                    style={{fontSize:'781.6506958px',lineHeight:'1.25',fill:'#40a184',fillOpacity:'1',strokeWidth:'1.05461192px'}}
                    id="path5164" />
                  <path
                    d="m 4774.3808,-147.23133 q 0,-53.32437 15.4564,-92.73803 15.4563,-40.18649 40.9593,-66.46226 25.503,-26.27578 58.7341,-39.41367 33.2312,-13.13789 68.0078,-13.13789 81.1459,0 124.4236,51.00593 43.2777,50.23309 43.2777,153.79056 0,4.63691 0,12.36508 0,6.95535 -0.7728,13.13789 h -275.1228 q 4.6369,62.598169 36.3224,95.05648 31.6855,32.4583115 98.9206,32.4583115 37.868,0 63.371,-6.1825356 26.2757,-6.9553529 39.4136,-13.1378879 l 10.0466,60.279722 q -13.1379,6.955352 -46.3689,14.683522 -32.4584,7.728169 -74.1905,7.728169 -52.5515,0 -91.1924,-15.456339 -37.8681,-16.229156 -62.5982,-44.0505659 -24.7301,-27.8214101 -37.0952,-65.6894401 -11.5923,-38.640846 -11.5923,-84.237046 z m 275.8957,-39.41367 q 0.7729,-48.68747 -24.7301,-79.60015 -24.7302,-31.68549 -68.7807,-31.68549 -24.7301,0 -44.0506,10.04662 -18.5476,9.2738 -31.6855,24.73015 -13.1378,15.45633 -20.8661,35.54958 -6.9553,20.09324 -9.2738,40.95929 z"
                    style={{fontSize:'781.6506958px',lineHeight:'1.25',fill:'#40a184',fillOpacity:'1',strokeWidth:'1.05461192px'}}
                    id="path5166" />
                </g>
                <path
                  id="path6010-7-78"
                  d="m 1051.463,-870.1327 a 73.973007,74.804164 9.8271012 0 0 -54.6579,59.68056 73.973007,74.804164 9.8271012 0 0 31.3365,74.58005 73.973007,74.804164 9.8271012 0 0 54.6538,-59.68446 73.973007,74.804164 9.8271012 0 0 -31.3324,-74.57615 z"
                  style={{fill:'#40a184',fillOpacity:'0.98039216',strokeWidth:'42.17207718',strokeLinecap:'round',strokeMiterlimit:'4',strokeDasharray:'none'}} />
                <path
                  id="path6012-2-8"
                  d="m 1200.085,-699.6369 a 73.973007,74.804164 89.25136 0 0 -4.1048,0.19717 74.804164,73.973007 0.74863971 0 0 -63.4554,42.51398 74.804164,73.973007 0.74863971 0 0 0.462,0.92135 73.973007,74.804164 89.25136 0 0 -0.462,0.92136 73.973007,74.804164 89.25136 0 0 63.4922,42.51841 74.804164,73.973007 0.74863971 0 0 4.068,0.19156 74.804164,73.973007 0.74863971 0 0 0.5668,0 73.973007,74.804164 89.25136 0 0 0.5709,0 73.973007,74.804164 89.25136 0 0 4.0681,-0.19156 74.804164,73.973007 0.74863971 0 0 2.267,-0.0843 74.804164,73.973007 0.74863971 0 0 61.2253,-42.4327 74.804164,73.973007 0.74863971 0 0 -0.4621,-0.92146 73.973007,74.804164 89.25136 0 0 0.4621,-0.92136 73.973007,74.804164 89.25136 0 0 -63.4552,-42.51388 74.804164,73.973007 0.74863971 0 0 -0.5668,-0.0622 74.804164,73.973007 0.74863971 0 0 -3.5384,-0.13526 74.804164,73.973007 0.74863971 0 0 -0.5709,0 73.973007,74.804164 89.25136 0 0 -0.5668,0 z"
                  style={{fill:'#40a184',fillOpacity:'0.98039216',strokeWidth:'42.17207718',strokeLinecap:'round',strokeMiterlimit:'4',strokeDasharray:'none'}} />
                <path
                  id="path6014-8-6"
                  d="m 1040.3446,-670.16863 a 196.16574,167.55824 0 0 0 -19.6021,0.0675 196.16574,167.55824 0 0 0 -185.45613,173.33874 196.16574,167.55824 0 0 0 0.41697,4.19317 h 44.09122 v -7.15397 h 0.1183 a 21.823958,46.855474 0 0 1 0.54106,-10.22515 196.16574,167.55824 0 0 1 0.0559,-3.56596 196.16574,167.55824 0 0 1 172.73288,-155.5962 196.16574,167.55824 0 0 0 -12.8996,-1.05662 z"
                  style={{fill:'#40a184',fillOpacity:'1',strokeWidth:'42.17207718',strokeLinecap:'round',strokeMiterlimit:'4',strokeDasharray:'none'}} />
                <path
                  id="path6016-2-04"
                  d="m 1028.1416,-577.02886 a 74.804164,73.973007 80.172899 0 0 -31.3365,74.58005 74.804164,73.973007 80.172899 0 0 54.6579,59.68451 74.804164,73.973007 80.172899 0 0 31.3324,-74.5801 74.804164,73.973007 80.172899 0 0 -54.6538,-59.68446 z"
                  style={{fill:'#40a184',fillOpacity:'0.98039216',strokeWidth:'42.17207718',strokeLinecap:'round',strokeMiterlimit:'4',strokeDasharray:'none'}} />
                <path
                  id="path6018-9-6"
                  d="m 633.61253,-870.1327 a 74.804164,73.973007 80.172899 0 1 54.65786,59.68056 74.804164,73.973007 80.172899 0 1 -31.33586,74.58005 74.804164,73.973007 80.172899 0 1 -54.65447,-59.68446 74.804164,73.973007 80.172899 0 1 31.33247,-74.57615 z"
                  style={{fill:'#3fa184',fillOpacity:'0.98039216',strokeWidth:'42.17207718',strokeLinecap:'round',strokeMiterlimit:'4',strokeDasharray:'none'}} />
                <path
                  id="path6020-96-7"
                  d="m 488.93742,-699.63584 a 74.804164,73.973007 0.74864 0 1 4.10473,0.19727 73.973007,74.804164 89.25136 0 1 63.45557,42.51387 73.973007,74.804164 89.25136 0 1 -0.4621,0.92136 74.804164,73.973007 0.74864 0 1 0.4621,0.92146 74.804164,73.973007 0.74864 0 1 -63.49216,42.51778 73.973007,74.804164 89.25136 0 1 -4.06814,0.19157 73.973007,74.804164 89.25136 0 1 -0.56689,0 74.804164,73.973007 0.74864 0 1 -0.5708,0 74.804164,73.973007 0.74864 0 1 -4.06814,-0.19157 73.973007,74.804164 89.25136 0 1 -2.26642,-0.0843 73.973007,74.804164 89.25136 0 1 -61.22574,-42.43491 73.973007,74.804164 89.25136 0 1 0.4621,-0.92145 74.804164,73.973007 0.74864 0 1 -0.4621,-0.92136 74.804164,73.973007 0.74864 0 1 63.45558,-42.51388 73.973007,74.804164 89.25136 0 1 0.56638,-0.0622 73.973007,74.804164 89.25136 0 1 3.53834,-0.13526 73.973007,74.804164 89.25136 0 1 0.5708,0 74.804164,73.973007 0.74864 0 1 0.56689,0 z"
                  style={{fill:'#41a184',fillOpacity:'0.98039216',strokeWidth:'42.17207718',strokeLinecap:'round',strokeMiterlimit:'4',strokeDasharray:'none'}} />
                <path
                  style={{fill:'#40a184',fillOpacity:'0.98039216',strokeWidth:'42.17207718',strokeLinecap:'round',strokeMiterlimit:'4',strokeDasharray:'none'}}
                  d="m 1028.1416,-574.03485 a 74.804164,73.973007 80.172899 0 0 -31.3365,74.58005 74.804164,73.973007 80.172899 0 0 54.6579,59.6845 74.804164,73.973007 80.172899 0 0 31.3324,-74.58009 74.804164,73.973007 80.172899 0 0 -54.6538,-59.68446 z"
                  id="path8195" />
                <path
                  id="circle8284"
                  d="m 644.48513,-530.65655 a 46.306762,46.306762 0 0 0 -41.08931,25.57895 46.306762,46.306762 0 0 0 20.67623,62.14199 46.306762,46.306762 0 0 0 62.14255,-20.68583 46.306762,46.306762 0 0 0 -20.68582,-62.13302 46.306762,46.306762 0 0 0 -21.04365,-4.90209 z m -34.79081,83.1564 -5.38948,25.91531 6.94479,2.84017 7.64928,-21.76327 a 54.095313,54.095313 0 0 1 -5.88649,-4.24616 54.095313,54.095313 0 0 1 -2.58662,-2.41865 l -0.73148,-0.32683 z m -3.63755,8.11699 -11.47165,1.93063 1.07802,3.32761 h 0.0791 l 9.40964,-0.30993 0.15784,-0.77823 0.85314,-4.10527 -0.0791,-0.0733 z"
                  style={{opacity:'0.98999999',fill:'#ff8e8e',fillOpacity:'1',strokeWidth:'42.17207718',strokeLinecap:'round',strokeMiterlimit:'4',strokeDasharray:'none'}} />
                <path
                  style={{fill:'#40a184',fillOpacity:'1',strokeWidth:'42.17207718',strokeLinecap:'round',strokeMiterlimit:'4',strokeDasharray:'none'}}
                  d="m 647.32585,-670.16863 a 196.16574,167.55824 0 0 1 19.6022,0.0675 196.16574,167.55824 0 0 1 185.45614,173.33874 196.16574,167.55824 0 0 1 -0.41698,4.19317 h -44.09123 v -7.15397 h -0.11828 a 21.823958,46.855474 0 0 0 -0.54107,-10.22515 196.16574,167.55824 0 0 0 -0.0559,-3.56596 196.16574,167.55824 0 0 0 -172.73292,-155.5962 196.16574,167.55824 0 0 1 12.8996,-1.05662 z"
                  id="path8440" />
                <text
                  style={{fontStyle:'normal',fontWeight:'normal',fontSize:'278.17977905px',lineHeight:'1.25',fontFamily:'sans-serif',letterSpacing:'0px',wordSpacing:'0px',fill:'#000000',fillOpacity:'1',stroke:'none',strokeWidth:'6.95449448'}}
                  x="4884.6909"
                  y="-794.25598"
                  id="text840" />
              <g
                id="g852">
                <g
                  id="g866">
                  <g
                    aria-label="R"
                    style={{fontStyle:'normal',fontWeight:'normal',fontSize:'11.29550457px',lineHeight:'0%',fontFamily:'sans-serif',letterSpacing:'0px',wordSpacing:'0px',fill:'#77777e',fillOpacity:'0.94117647',stroke:'none',strokeWidth:'0.94129211px',strokeLinecap:'butt',strokeLinejoin:'miter',strokeOpacity:'1'}}
                    id="text4145">
                    <path
                      d="m 5224.7481,-309.99806 q 1.584,1.97999 3.96,5.24697 2.4749,3.16798 5.0489,7.12795 2.574,3.86098 5.049,8.11795 2.574,4.25698 4.356,8.21695 h -10.494 q -1.98,-3.76198 -4.3559,-7.62295 -2.277,-3.86098 -4.653,-7.32596 -2.376,-3.56397 -4.752,-6.63296 -2.277,-3.06898 -4.158,-5.34596 -1.2869,0.099 -2.6729,0.099 -1.287,0 -2.673,0 h -8.613 v 26.82883 h -9.6029 v -67.61658 q 4.059,-0.99 9.0089,-1.28699 5.049,-0.396 9.207,-0.396 14.4539,0 21.9778,5.44497 7.623,5.44496 7.623,16.2359 0,6.83095 -3.663,11.68192 -3.564,4.85097 -10.5929,7.22696 z m -14.5529,-32.1748 q -6.138,0 -9.405,0.297 v 25.83884 h 6.831 q 4.95,0 8.9099,-0.495 3.96,-0.495 6.633,-1.88099 2.772,-1.38599 4.257,-3.86098 1.485,-2.57398 1.485,-6.73195 0,-3.86098 -1.485,-6.43496 -1.485,-2.57399 -4.059,-4.05898 -2.475,-1.48499 -5.94,-2.07899 -3.366,-0.59399 -7.2269,-0.59399 z"
                      style={{fontStyle:'normal',fontVariant:'normal',fontWeight:'normal',fontStretch:'normal',fontSize:'98.99938965px',lineHeight:'1.25',fontFamily:'Ubuntu',InkscapeFontSpecification:'Ubuntu',fill:'#77777e',fillOpacity:'0.94117647',strokeWidth:'0.94129211px'}}
                      id="path844"
                    />
                  </g>
                  <circle
                    style={{opacity:'1',fill:'none',fillOpacity:'1',stroke:'#77777e',strokeWidth:'10.19865894',strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:'4',strokeDasharray:'none',strokeOpacity:'0.94117647',paintOrder:'normal'}}
                    id="path842"
                    cx="5213.936"
                    cy="-315.45145"
                    r="55.243328" />
                </g>
              </g>
            </g>
            </g>
          </svg>
      </div>
      <div className="hero-text animated fadeIn">
        <h1 className="responsive-headline">{content.headline}</h1>
        <p>{content.body}</p>
      </div>
    </div>
    <div className="buttons">
      {/* <AnchorLink className="button trial animated" to={ content.button1.to }>{ content.button1.label }</AnchorLink> */}
      <a className="button learn-more animated fadeIn" id="work-with-us-on-upwork-button" href={content.button2.to} target="_blank" rel="noreferrer">{content.button2.label}</a>
    </div>
    </div>
  </section >
)
